import React from "react";
import { PageProps } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { InquiryForm } from "../components/inquiry-form";

const Commissions: React.FC<PageProps<void>> = ({ path, data }: any) => (
  <>
    <Layout path="/commissions">
      <SEO title="Commissions" />
      <h1>Commissions</h1>
      <section className="padding" id="section-about">
        <h2>About</h2>
        <p>
          Hello! I'm Daniel. I write code for a living and I'm a furry! I made
          this website to show off my character, Daitarou, and thought it would
          be cool to make character or artist sites for others!
        </p>
        <p>
          I ask that you please read everything on this page before you decide
          to commission me!
        </p>
      </section>
      <section className="padding" id="section-terminology">
        <h2>Terminology</h2>
        <p>
          Here are a few words that I use that may not be familiar to everyone.
        </p>
        <ul>
          <li>
            <strong>Domain</strong>: The base part of a url, the part before and
            after the last period. For example, in
            <code>https://daitarou.info/commissions</code>, the domain is
            <code>daitarou.info</code>. It costs money to rent domains and point
            them to your content, though it's usually fairly cheap at about $12
            per year.
          </li>
          <li>
            <strong>Hosting</strong>: This is where your website lives. It's the
            computer(s) that actually run the code necessary for users to reach
            your site. Nowadays, hosting is usually in the cloud. Depending on
            the popularity and type of website, hosting could cost anywhere from
            $0 to $500 per month. Average WordPress hosting costs about
            $20/month. <em>If you commission me, it'll cost you nothing</em>.
          </li>
        </ul>
      </section>
      <section className="padding" id="section-what-you-get">
        <h2>What you get</h2>
        <h3>A professionally-designed website</h3>
        <p>
          I write all kinds of code for my job, but web design is my passion.
          Expect your site to look good, load fast, and fit your design!
        </p>
        <p>
          If you'd like samples of my work, check out my{" "}
          <a href="https://github.com/danielnoon">GitHub</a> for now. I will
          make a portfolio site soon!
        </p>
        <h3>Free hosting*</h3>
        <p>
          That's right! No more paying $20/month to host your website. Your
          pages are statically generated, which means the files can be hosted
          for free on a worldwide CDN provided by{" "}
          <a href="https://vercel.com">Vercel</a>!
        </p>
        <p>
          <small>
            * As long as your site doesn't exceed 100GB of bandwidth per month.
            I don't forsee this happening in most cases.
          </small>
        </p>
        <h3>Free domain for 1 year</h3>
        <p>
          I'll cover the first year of DNS registration, as long as your
          preferred domain is available and reasonably priced. It costs about
          $12/year after that.
        </p>
        <h3>Free minor edits</h3>
        <p>
          Small edits like changing the theme color, swapping an image, editing
          text, etc. are covered. Just send me a message and I'll make the
          change!
        </p>
        <h3>Discounted major edits</h3>
        <p>
          Additional pages, redesigns, and interactive sections are available at
          a discounted price after completion.
        </p>
        <p>
          <strong>Note:</strong> I will not accept major edits until two months
          have elapsed. I'm doing this as a service to my customers and I would
          rather not have someone take advantage of it. Please make sure you
          know what you want before you make your submission.
        </p>
        <h3>It's yours</h3>
        <p>
          Upon completion, you will receive the complete source code for your
          website to use as you wish. If you're technically-adept, feel free to
          make your own changes or publish it with your preferred hosting
          provider.
        </p>
        <p>
          Though it's yours, I'd request that you leave some kind of credit to
          me on the site. In addition, please don't resell the code or designs
          to others.
        </p>
      </section>
      <section className="padding" id="section-price">
        <h2>Price</h2>
        <p>
          All pricing will by manually quoted; however, expected prices are
          outlined below.
        </p>
        <ul>
          <li>
            <p>Base price: $70</p>
            <p>
              <small>
                Includes domain, hosting, design, and one simple page. Dynamic
                page +$15.
              </small>
            </p>
          </li>
          <li>
            <p>Simple pages: $20</p>
            <p>
              <small>
                Static, simple page, not intended to be changed significantly in
                the future.
              </small>
            </p>
          </li>
          <li>
            <p>Dynamic pages: $30+</p>
            <p>
              <small>
                Custom page that allows for the owner to make changes or add to
                the content at any time without any knowledge of how to code.
              </small>
            </p>
          </li>
          <li>
            <p>Complex styles and animations: $20+</p>
            <p>
              <small>
                Any kind of complex animation falls under this category. Simple
                animations, such as basic hover transitions as seen on my
                homepage's gallery, do not cost extra.
              </small>
            </p>
          </li>
          <li>
            <p>Vanity domain: $10+</p>
            <p>
              <small>
                Not all domains are the same. Some domains are much more
                expensive than others. Domains that are included in the cost of
                your site cost about $10-20 per year. Any more than that costs
                extra.
              </small>
            </p>
          </li>
        </ul>
        <p>
          I won't charge for any reasonable amount of images. However, if you're
          looking for a full-fledged gallery website where you want to organize
          and upload massive amounts of images, I urge you to look into
          alternatives. I am building an open-source gallery manager called{" "}
          <a href="https://github.com/danielnoon/wtag">WTag</a> over on{" "}
          <a href="https://github.com/danielnoon/wtag">GitHub</a> that you are
          welcome to use!
        </p>
        <p>
          If you are interested, I would be happy to set up an instance and host
          it for you. Unfortunately, unlike static websites, hosting is not
          free.
        </p>
        <ul>
          <li>
            WTag instance:
            <ul>
              <li>
                OSS:{" "}
                <a href="https://github.com/danielnoon/wtag">
                  host it yourself!
                </a>
              </li>
              <li>Managed: $15.00/month</li>
            </ul>
          </li>
        </ul>
        <h3>Refunds and Cancellations</h3>
        <p>
          If you decide to cancel your commission, the amount refunded will
          depend on several factors:
        </p>
        <ul>
          <li>
            If I have not started work on your site in any capacity, I will
            refund the entire cost.
          </li>
          <li>
            Once I have started work, I will refund half of the base price.
          </li>
          <li>I will refund the full amount of pages not yet started.</li>
          <li>I will not refund partially-completed pages.</li>
        </ul>
      </section>
      <section className="padding" id="section-process">
        <h2>Process</h2>
        <p>
          Alright, so this looks like something you'd be interested in. Where do
          we start?
        </p>
        <ol>
          <li>
            <p>Send in an interest form!</p>
            <p>
              Make sure to include as much detail as possible in the
              description. Some things to consider: include descriptions of{" "}
              <em>every page</em> that you would like. What kind of content do
              you want to add in the future? Describe the type of overall
              feeling the site should have--is it fun? Happy? Serious? Sad? What
              kind of color scheme should it have? Include a link to some
              reference images of your character if applicable.
            </p>
          </li>
          <li>
            I will get back to you soon to get started fleshing out the site.
            We'll work together to make sure I know what you want. You'll get at
            least one screenshot of a very rough mock-up. Once I feel
            comfortable that I know how to proceed, I'll ask for payment.
          </li>
          <li>
            As I work on your site, you will have access to an up-to-date,
            working version of your code. When I finish something big, I'll ask
            you for any changes you'd like to have made before I continue. Reach
            out at any time if you want me to change something; the sooner I
            know, the easier it is to fix.
          </li>
          <li>
            Once I complete your site, I will confirm with you that everything
            looks good. Then, I will enable your main site and work with you to
            make sure you have complete access to the source code, DNS
            management, and content files. As long as you promise to keep some
            kind of attribution to me and not sell it, you can use it however
            you'd like.
          </li>
        </ol>
      </section>
      <section className="padding" id="section-faq">
        <h2>FAQ</h2>
        <ul>
          <li>
            The cost outlined above is not a subscription, it is a one-time
            payment, aside from the domain.
          </li>
        </ul>
        <p>
          <em>More content will be added as I receive more questions.</em>
        </p>
        <p>
          Please ask me some! There is an inquiry form below for you to use!
        </p>
      </section>
      <section className="padding" id="section-inquiry-form">
        <InquiryForm></InquiryForm>
      </section>
    </Layout>
  </>
);

export default Commissions;
