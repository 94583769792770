import React, { FormEvent, ChangeEvent, Component } from "react";

interface InquiryFormProps {}

interface InquiryFormState {
  submitted: boolean;
  success: boolean;
  name: string;
  email: string;
  body: string;
}

export class InquiryForm extends Component<InquiryFormProps, InquiryFormState> {
  constructor(props: InquiryFormProps) {
    super(props);

    this.state = {
      success: true,
      submitted: false,
      name: "",
      email: "",
      body: "",
    };
  }

  updateValue(ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.setState({ [ev.target.name]: ev.target.value } as any);
  }

  getNotification() {
    if (!this.state.submitted) return null;

    if (this.state.success) {
      return (
        <div className="notification success">
          <div className="notification-header">
            <strong>Submitted!</strong>
          </div>
          <div className="notification-body">
            <p>
              Be on the lookout for an email from daitarou [at] outlook.com
              soon!
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="notification failure">
          <div className="notification-header">
            <strong>Something went wrong!</strong>
          </div>
          <div className="notification-body">
            <p>That could be my fault! But double check that:</p>
            <ul>
              <li>Everything is filled out</li>
              <li>You are connected to the internet</li>
            </ul>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <h2>Inquiry Form</h2>
        {this.getNotification()}
        <form
          id="inquiry"
          method="post"
          action="/api/submit-inquiry"
          onSubmit={ev => this.submit(ev)}
        >
          <div className="form-block">
            <input
              type="text"
              name="name"
              placeholder="Name"
              required
              value={this.state.name}
              onChange={ev => this.updateValue(ev)}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              value={this.state.email}
              onChange={ev => this.updateValue(ev)}
            />
          </div>
          <div className="form-block">
            <textarea
              name="body"
              placeholder="Inquiry"
              rows={5}
              required
              value={this.state.body}
              onChange={ev => this.updateValue(ev)}
            />
          </div>
          <div className="form-block">
            <input type="submit"></input>
          </div>
        </form>
      </div>
    );
  }

  submit(ev: FormEvent) {
    ev.preventDefault();
    this.sendEmail();
  }

  async sendEmail() {
    const { name, email, body } = this.state;
    const data = await fetch("/api/submit-inquiry", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ name, email, body }),
    })
      .then(res => res.json())
      .catch(err => {
        console.error(err);
        return { success: false };
      });

    if (data && data.success) {
      this.setState({
        submitted: true,
        success: true,
        body: "",
        email: "",
        name: "",
      });
    } else {
      this.setState({ submitted: true, success: false });
    }
  }
}
